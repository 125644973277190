<template>
  <div>
    <!-- 检索 -->
    <el-form :inline="true" :model="form" ref="ruleForm">
      <el-form-item class="form-item" label="部门名称" prop="title">
        <el-input
          v-model="form.title"
          placeholder="请输入部门名称"
          @keyup.enter="onSubmit"
        ></el-input>
      </el-form-item>
      <el-form-item class="form-item" label="部门状态" prop="bg_app">
        <el-select v-model="form.bg_app" placeholder="全部" clearable>
          <el-option label="待上线" value="1"></el-option>
          <el-option label="上线" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" label="审核状态" prop="status">
        <el-select
          v-model="form.status"
          placeholder="全部"
          multiple
          collapse-tags
        >
          <el-option label="待审核" value="1"></el-option>
          <el-option label="审核不通过" value="2"></el-option>
          <el-option label="审核通过" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" label="反馈周期" prop="createtime">
        <el-date-picker
          v-model="createtime"
          :editable="false"
          type="datetimerange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          class="date-input"
          @change="handCreateTime"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item class="form-item form-submit">
        <el-button type="primary" @click="onSubmit">查询</el-button>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
    <!-- 检索 -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        title: "",
        bg_app: "",
        status: [],
        createtime: "",
      },
      createtime: "",
    };
  },
  methods: {
    // 检索时间格式转换
    handCreateTime(e) {
      if (e) {
        let start_m =
          new Date(e[0]).getMonth() + 1 > 9
            ? new Date(e[0]).getMonth() + 1
            : "0" + (new Date(e[0]).getMonth() + 1);
        let start_d =
          new Date(e[0]).getDate() > 9
            ? new Date(e[0]).getDate()
            : "0" + new Date(e[0]).getDate();
        let start_h =
          new Date(e[0]).getHours() > 9
            ? new Date(e[0]).getHours()
            : "0" + new Date(e[0]).getHours();
        let start_f =
          new Date(e[0]).getMinutes() > 9
            ? new Date(e[0]).getMinutes()
            : "0" + new Date(e[0]).getMinutes();
        let start_s =
          new Date(e[0]).getSeconds() > 9
            ? new Date(e[0]).getSeconds()
            : "0" + new Date(e[0]).getSeconds();
        let end_m =
          new Date(e[1]).getMonth() + 1 > 9
            ? new Date(e[1]).getMonth() + 1
            : "0" + (new Date(e[1]).getMonth() + 1);
        let end_d =
          new Date(e[1]).getDate() > 9
            ? new Date(e[1]).getDate()
            : "0" + new Date(e[1]).getDate();
        let end_h =
          new Date(e[1]).getHours() > 9
            ? new Date(e[1]).getHours()
            : "0" + new Date(e[1]).getHours();
        let end_f =
          new Date(e[1]).getMinutes() > 9
            ? new Date(e[1]).getMinutes()
            : "0" + new Date(e[1]).getMinutes();
        let end_s =
          new Date(e[1]).getSeconds() > 9
            ? new Date(e[1]).getSeconds()
            : "0" + new Date(e[1]).getSeconds();
        this.form.createtime =
          new Date(e[0]).getFullYear() +
          "-" +
          start_m +
          "-" +
          start_d +
          " " +
          start_h +
          ":" +
          start_f +
          ":" +
          start_s +
          "~" +
          new Date(e[1]).getFullYear() +
          "-" +
          end_m +
          "-" +
          end_d +
          " " +
          end_h +
          ":" +
          end_f +
          ":" +
          end_s;
      } else {
        this.form.createtime = "";
      }
    },
  },
};
</script>

<style></style>
